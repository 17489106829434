import { useEffect } from 'react';
import { useCompanyContext } from './CompanyContext';
import { constants } from '../../utils/constants';

export const FormikChangeComponent = ({
  values,
  setSubmitting,
  isSubmitting,
  dirty,
  notValidRoleType,
  countryPartitionKey,
  formDispatch,
}) => {
  useEffect(() => {
    setSubmitting(false);
  }, [values, setSubmitting]);

  const { setShowConfirmClose } = useCompanyContext();

  useEffect(() => {
    setShowConfirmClose && setShowConfirmClose((dirty && !isSubmitting) || notValidRoleType);
  }, [dirty, isSubmitting, setShowConfirmClose, notValidRoleType]);

  useEffect(() => {
    formDispatch({ type: constants.SET_IS_DIRTY, value: dirty });
  }, [dirty]);

  return null;
};

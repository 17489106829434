import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getFriendlyDateTime } from '../../utils/date';
import { getUser, User } from '../../services/getUser';
import { AcceptanceLog } from "../../services/useAcceptanceLogs";
import { AcceptanceTypeComponent } from "./AcceptanceTypeComponent";
import { AdditionalLogInfo } from "./AdditionalLogInfo";
import { getPerson, Person } from "../../services/getPerson";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(80px, 1fr)) minmax(150px, 2fr) 50px;
  grid-gap: 8px;
  align-items: center;
  padding: 10px 20px;
  word-wrap: break-word;
  &:nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;

export const LogDataOrganizationPerson = ({ item }: {item: AcceptanceLog}) => {
    const [user, setUser] = useState({} as User);
    const [person, setPerson] = useState({} as Person);

    useEffect(() => {
        item.userId &&
        getUser(item.userId).then((response) => {
            setUser(response);
        });
    }, [item.userId]);

    useEffect(() => {
        item.personId &&
        getPerson(item.personId).then((response) => {
            setPerson(response);
        });
    }, [item.personId]);

    return (
        <Grid>
            <AcceptanceTypeComponent item={item}/>
            <span>{person.firstName} {person.lastName} ({person.nationality?.displayName})</span>
            <span>{getFriendlyDateTime(item.acceptedAt)}</span>
            <span>{user?.name || item.userId}</span>
            <span>{item.comments}</span>
            <AdditionalLogInfo item={item}/>
        </Grid>
    );
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFriendlyDateTime } from '../../utils/date';
import { getUser } from '../../services/getUser';
import { AcceptanceTypeComponent } from "./AcceptanceTypeComponent";
import { AdditionalLogInfo } from "./AdditionalLogInfo";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(80px, 1fr)) minmax(150px, 2fr) 50px;
  grid-gap: 8px;
  align-items: center;
  padding: 10px 20px;
  word-wrap: break-word;
  &:nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;

export const LogDataOrganization = ({ item }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    item.userId &&
      getUser(item.userId).then((response) => {
        setUser(response);
      });
  }, [item.userId]);

  return (
    <Grid>
      <AcceptanceTypeComponent item={item}/>
      <span></span>
      <span>{getFriendlyDateTime(item.acceptedAt)}</span>
      <span>{user.name}</span>
      <span>{item.comments}</span>
      <AdditionalLogInfo item={item} />
    </Grid>
  );
};

LogDataOrganization.propTypes = {
  item: PropTypes.object,
};

import { constants } from '../utils/constants';

export const initialState = (clientRoleType) => {
    return {
        isNewPersonFormOpen: false,
        showConfirmRefresh: false,
        showSaveConflict: false,
        isSaving: false,
        isRemoving: false,
        isError: false,
        errorMessage: null,
        isDirty: false,
        updatedBy: '',
        showRoleExistsMessage: false,
        initialRoleType: clientRoleType,
    }
}

export const reducer = (state, action) => {
    switch (action.type) {
        case constants.SET_IS_NEW_PERSON_FORM_OPEN:
            return { ...state, isNewPersonFormOpen: action.value };
        case constants.SET_SHOW_CONFIRM_REFRESH:
            return { ...state, showConfirmRefresh: action.value };
        case constants.SET_SHOW_SAVE_CONFLICT:
            return { ...state, showSaveConflict: action.value };
        case constants.SET_IS_SAVING:
            return { ...state, isSaving: action.value };
        case constants.SET_IS_REMOVING:
            return { ...state, isRemoving: action.value };
        case constants.SET_IS_ERROR:
            return { ...state, isError: action.value };
        case constants.SET_ERROR_MESSAGE:
            return { ...state, errorMessage: action.value };
        case constants.SET_IS_DIRTY:
            return { ...state, isDirty: action.value };
        case constants.SET_UPDATED_BY:
            return { ...state, updatedBy: action.value };
        case constants.SET_SHOW_ROLE_EXISTS_MESSAGE:
            return { ...state, showRoleExistsMessage: action.value };
        case constants.SET_INITIAL_ROLE_TYPE:
            return { ...state, initialRoleType: action.value };
        default:
            return state;
    }
};

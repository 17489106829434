import React from "react";
import styled from "styled-components";
import { HighRisk, MissingInfo, MissingRole } from "@instech/icons";
import { AcceptanceLog } from "../../services/useAcceptanceLogs";
import { AcceptanceReviewStatus, AcceptanceType } from "../../utils/AcceptanceType";

const MissingInfoAccepted = styled(MissingInfo)`
  color: transparent;
`;
const HighRiskAccepted = styled(HighRisk)`
  color: transparent;
`;
const HighRiskRejected = styled(HighRisk)`
  color: ${(props) => props.theme.status.red};
`;
const HighRiskPending = styled(HighRisk)`
  color: ${(props) => props.theme.status.red};
  rect {
    fill-opacity: 0.35;
  }
`;
const MissingRoleAccepted = styled(MissingRole)`
  color: transparent;
`;

const LogDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    padding-left: 8px;
  }
`;

export const AcceptanceTypeComponent =({item}: {item: AcceptanceLog}) => {

    return (
        <span>
        {item?.acceptanceType === AcceptanceType.MissingData ? (
            <LogDescription>
                <MissingInfoAccepted/>
                <span>Missing Info Accepted</span>
            </LogDescription>
        ) : item?.acceptanceType === AcceptanceType.RiskLevel && item?.acceptanceReviewStatus === AcceptanceReviewStatus.Pending ? (
            <LogDescription>
                <HighRiskPending/>
                <span>High Risk Pending</span>
            </LogDescription>
        ) : item?.acceptanceType === AcceptanceType.RiskLevel && item?.acceptanceReviewStatus === AcceptanceReviewStatus.Rejected ? (
            <LogDescription>
                <HighRiskRejected/>
                <span>High Risk Rejected</span>
            </LogDescription>
        ) : item?.acceptanceType === AcceptanceType.RiskLevel ? (
            <LogDescription>
                <HighRiskAccepted/>
                <span>High Risk Accepted</span>
            </LogDescription>
        ) : item?.acceptanceType === AcceptanceType.MissingPersonRoles ? (
            <LogDescription>
                <MissingRoleAccepted/>
                <span>Missing Person Role</span>
            </LogDescription>
        ) : (
            <span>Other</span>
        )}
      </span>
    );
}

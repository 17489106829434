import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Company } from '../company/Company';
import { BoxButton } from '@instech/components';
import { CompanySearch } from './CompanySearch';
import { Trash, Load, Add } from '@instech/icons';
import { RemoveCompanyRole } from '../../services/postPutCompanyData';
import { WarningNotification } from '@instech/components';
import { useEventStream } from '../../services/useEventStream';
import { useInsuredObjectDetails } from '../../services/useInsuredObjectDetails';
import { useOrganizationRoles } from '../../services/useOrganizationRoles';
import { SelectOwners } from '../objectOwnersWizard/SelectOwners';
import { getSourceSystem } from 'utils/getSourceSystem';
import { CompanyProvider } from '../company/CompanyContext';
import { HasFlag, ParseValidationFlags } from '../../utils/validations/functions';
import { MissingData } from '../../utils/validations/types';
import { useAppContext } from '../appRouting/AppContext';
import { RevalidateResyncButtons } from '../shared/RevalidateResyncButtons';
import { PersonRoles } from '../companyRoles/PersonRoles';
import { Formik } from 'formik';

const WarningNotificationWrapper = styled.div`
  margin: 16px 0;
`;
const StyledTrash = styled(Trash)`
  color: ${(props) => props.theme.lightRed};
  align-self: center;
  margin-bottom: 14px;
  transition: color 1s ease-out;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.red};
  }
`;
const StyledDisabledTrash = styled(StyledTrash)`
  color: ${(props) => props.theme.mediumGray};
  &:hover {
    cursor: default;
  }
`;
const StyledLoad = styled(Load)`
  align-self: center;
  height: 14px;
`;
const ErrorMessage = styled.div`
  color: red;
`;
const Wrapper = styled.div`
  background: ${(props) => props.theme.marineBlue10};
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue};
  margin-bottom: 4px;
`;
const ButtonWrapper = styled.div`
  align-self: end;
  justify-self: left;
`;
const Section = styled.div`
  margin: 32px 0;
  display: flex;
  flex-direction: column;
`;
const SearchWrapper = styled.div`
  margin: 32px 0;
`;
const Row = styled.div`
  align-items: flex-end;
`;
const CompanyWrapper = styled.div`
  display: grid;
  grid-template-columns: 20fr 0.5fr;
  grid-gap: 16px;
`;
const OwnersFromLLI = styled.button`
  color: ${(props) => props.theme.darkGreen};
  text-decoration: underline;
  background: none;
  outline: none;
  border: none;
  text-align: start;
  cursor: pointer;
  margin: 8px 0 16px 0;
  padding: 0;
`;

const separateByRoleType = (organizations) => {
  let peti = [];
  let assured = [];
  let coAssured = [];

  organizations?.forEach((item) => {
    if (item.role.roleType.roleName === 'PETI') {
      peti.push(item);
    } else if (item.role.roleType.isCoAssured) {
      coAssured.push(item);
    } else {
      assured.push(item);
    }
  });
  return { assured, coAssured, peti };
};

export const VesselData = ({
  fleetId,
  fleetName,
  insuranceYear,
  vesselId,
  onUpdate,
  mutateFleetDetails,
  isCoAssured,
  fleetCovers,
  claims,
  mutateWizardValidationTasks,
  locationState,
  setLocationState,
}) => {
  const { state, isAdministrator, isDataAdministrator } = useAppContext();
  const [isNewUboOpen, setIsNewUboOpen] = useState(false);
  const newCompany = state.newCompany;
  const { data: object, mutate } = useInsuredObjectDetails(fleetId, vesselId);
  const validations = ParseValidationFlags(object?.validations);
  const roles = object ? object.roles : [];
  const uboValues = { roles, _etag: object?._etag };

  const isMissingBeneficialOwner = HasFlag(validations.missingData, MissingData.BeneficialOwner);
  const isMissingUltimateBeneficialOwner = HasFlag(validations.missingData & ~validations.acceptedMissingData, MissingData.UltimateBeneficialOwner);

  const { data: organizationRoles, mutate: mutateOrganizationRoles } = useOrganizationRoles(fleetId, vesselId);

  const vesselCovers = object?.covers || [];
  const update = async () => {
    await mutate();
    await mutateOrganizationRoles();
    await mutateFleetDetails();
    onUpdate();
  };

  const newRoleCovers =
    vesselCovers &&
    fleetCovers &&
    fleetCovers.filter((fc) => vesselCovers.some((vc) => vc.id === fc.id)).map((vi) => vi.interestCode);

  const { assured, coAssured, peti } = separateByRoleType(organizationRoles);

  if (newCompany) {
    if (isCoAssured) coAssured.push({ sources: [{ mdmReference: { sourceSystem: 'Kyc' } }], ...newCompany });
    if (!isCoAssured) assured.push({ sources: [{ mdmReference: { sourceSystem: 'Kyc' } }], ...newCompany });
  }

  const [isSearchAssuredOpen, setIsSearchAssuredOpen] = useState(false);
  const [isSearchCoAssuredOpen, setIsSearchCoAssuredOpen] = useState(false);

  const [isError, setIsError] = useState(false);

  const [loadingId, setLoadingId] = useState(null);

  const [isOwnersListOpen, setIsOwnersListOpen] = useState(false);
  const handleOwnersListOpen = () => {
    setIsOwnersListOpen((isOwnersListOpen) => !isOwnersListOpen);
  };

  const handleIsSearchAssuredOpen = () => {
    setIsSearchAssuredOpen((isSearchAssuredOpen) => !isSearchAssuredOpen);
  };
  const handleIsSearchCoAssuredOpen = () => {
    setIsSearchCoAssuredOpen((isSearchCoAssuredOpen) => !isSearchCoAssuredOpen);
  };

  const handleRemoveCompanyRole = async (fleetId, vesselId, organizationRoleId, etag) => {
    setIsError(false);
    const role = {
      fleetId: fleetId,
      objectId: vesselId,
      organizationRoleId: organizationRoleId,
    };
    setLoadingId(organizationRoleId);
    await RemoveCompanyRole(role, etag)
      .then(async () => {
        await mutateFleetDetails();
        await update();
        setLoadingId(null);
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
      });
  };

  const missingBOnotificationText = `Add at least one active company as a Beneficial Owner.`;
  const missingUBOnotificationText = `Add an Ultimate Beneficial Owner (UBO) to this object`;
  const missingUBOandBOnotificationText = `Add at least one active company as a Beneficial Owner and a person as an active Ultimate Beneficial Owner in a company`;

  const eventStreamTopic = `/Fleet/${fleetId}/${fleetId}/InsuredObject/${vesselId}/*`;

  const { onEvent, onConnect, onDisconnect, eventHandlerCleanup } = useEventStream(eventStreamTopic);

  onConnect(
    useCallback(() => {
      console.log('Connected to event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  onDisconnect(
    useCallback(() => {
      console.log('Disconnected from event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  useEffect(() => {
    onEvent((event) => {
      if (!event) {
        return;
      }

      if (event.type.endsWith('InsuredObjectFlagsChangedEvent')) {
        //console.info('Received event on topic vessel', eventStreamTopic, event);
        update();
      }
    });

    return eventHandlerCleanup;
  });

  return (
    <Wrapper>
      <Header>
        <OwnersFromLLI onClick={handleOwnersListOpen}>OWNER INFORMATION FROM LLI</OwnersFromLLI>
        <RevalidateResyncButtons entityId={vesselId} entityType={'InsuredObject'} entityPartitionKey={fleetId} />
      </Header>

      {isOwnersListOpen && (
        <SelectOwners
          fleetName={fleetName}
          insuranceYear={insuranceYear}
          fleetId={fleetId}
          vesselId={vesselId}
          vesselName={object?.name}
          handleClose={handleOwnersListOpen}
          mutateVesselList={update}
          mutateWizardValidationTasks={mutateWizardValidationTasks}
        />
      )}
      {
        <Formik enableReinitialize={true} initialValues={uboValues}>
          {({ values }) => {
            return (
              <PersonRoles
                title="UBO(s)"
                isNewPersonFormOpen={isNewUboOpen}
                openNewPersonForm={() => setIsNewUboOpen((isNewUboOpen) => !isNewUboOpen)}
                mutateVesselList={update}
                values={values}
                buttonName="ADD UBO"
                addIcon
                fleetId={fleetId}
                vesselId={vesselId}
                locationState={locationState}
                vesselContext={{isMissingUbo: isMissingUltimateBeneficialOwner}}
              />
            );
          }}
        </Formik>
      }
      {peti && peti.length > 0 && (
        <Section>
          <Title>PETI</Title>
          <Row>
            <div>
              {peti.map((item) => {
                const role = item.role;
                const clientRoleType = { roleType: role.roleType };
                const validations = ParseValidationFlags(item.insuredObjectValidations[vesselId]);
                return (
                  <CompanyProvider
                    key={role.organization.id}
                    client={role.organization}
                    clientRoleType={clientRoleType}
                    activePeriod={role.activePeriod}
                    covers={item.interestCodes}
                    fleetCovers={fleetCovers}
                    roleId={role.id}
                    roleEtag={role._etag}
                    claims={claims}
                    fleetId={fleetId}
                    vesselId={vesselId}
                    mutateFleetDetails={mutateFleetDetails}
                    mutateVesselList={update}
                    roleSourceSystem={getSourceSystem(role)}
                    locationState={locationState}
                    setLocationState={setLocationState}
                    validations={validations}
                    isCompanyRole={true}
                    entityType={'OrganizationRole'}
                  >
                    <Company />
                  </CompanyProvider>
                );
              })}
            </div>
            <div></div>
          </Row>
        </Section>
      )}
      {isMissingBeneficialOwner && !isMissingUltimateBeneficialOwner && (
        <WarningNotificationWrapper>
          <WarningNotification size="small" headingText={missingBOnotificationText} />
        </WarningNotificationWrapper>
      )}
      {isMissingUltimateBeneficialOwner && !isMissingBeneficialOwner && (
        <WarningNotificationWrapper>
          <WarningNotification size="small" headingText={missingUBOnotificationText} />
        </WarningNotificationWrapper>
      )}
      {isMissingBeneficialOwner && isMissingUltimateBeneficialOwner && (
        <WarningNotificationWrapper>
          <WarningNotification size="small" headingText={missingUBOandBOnotificationText} />
        </WarningNotificationWrapper>
      )}
      {assured && assured.length > 0 && (
        <Section>
          <Title>Assured</Title>
          <Row>
            {assured.map((item, index) => {
              const role = item.role ?? item;
              const clientRoleType = { roleType: role?.roleType };
              const flags = item.insuredObjectValidations ? item.insuredObjectValidations[vesselId] : null;
              const validations = ParseValidationFlags(flags);
              return (
                <CompanyWrapper key={`${role.organization.id}${index}`}>
                  <CompanyProvider
                    client={role.organization}
                    clientRoleType={clientRoleType}
                    activePeriod={role.activePeriod}
                    roleId={role.id}
                    roleEtag={role._etag}
                    ownerIsAssured={role.ownerIsAssured}
                    covers={item.interestCodes}
                    fleetCovers={fleetCovers}
                    claims={claims}
                    fleetId={fleetId}
                    vesselId={vesselId}
                    mutateFleetDetails={mutateFleetDetails}
                    mutateVesselList={update}
                    isRoleTypeEditable={true}
                    organizationsListForChecking={assured}
                    organizationForCheckingIndex={index}
                    isTitleAsAssured={role.roleType.roleName === 'Owner' && role.ownerIsAssured}
                    roleSourceSystem={getSourceSystem(role)}
                    isCompanyRole={true}
                    locationState={locationState}
                    setLocationState={setLocationState}
                    validations={validations}
                    entityType={'OrganizationRole'}
                  >
                    <Company />
                  </CompanyProvider>

                  {getSourceSystem(role) !== 'Ins2000' && (
                    <StyledTrash
                      data-test-id="remove-company-role"
                      onClick={() => {
                        handleRemoveCompanyRole(fleetId, vesselId, role.id, object?._etag);
                      }}
                    />
                  )}
                  {loadingId && loadingId != role.id && !isError && <StyledDisabledTrash />}
                  {isError && loadingId === role.id && <ErrorMessage>Failed to remove company role.</ErrorMessage>}
                  {loadingId && loadingId === role.id && !isError && <StyledLoad />}
                </CompanyWrapper>
              );
            })}
          </Row>
        </Section>
      )}
      {isSearchAssuredOpen || assured.length === 0 ? (
        <SearchWrapper>
          <CompanySearch
            fleetId={fleetId}
            vesselId={vesselId}
            sectionTitle="Assured"
            onAdd={update}
            mutateFleetDetails={mutateFleetDetails}
            setIsSearchAssuredOpen={setIsSearchAssuredOpen}
            covers={newRoleCovers}
            organizationsListForChecking={assured}
          />
        </SearchWrapper>
      ) : (
        <ButtonWrapper>
          <BoxButton
            data-test-id="add-assured-company"
            aria-label="Add Assured"
            onClick={handleIsSearchAssuredOpen}
            padding="10px 20px"
            startIcon={<Add />}
            type="button"
          >
            ASSURED
          </BoxButton>
        </ButtonWrapper>
      )}
      {coAssured && coAssured.length > 0 && (
        <Section>
          <Title>Co-assured</Title>
          <Row>
            {coAssured.map((item, index) => {
              const role = item.role ?? item;
              const clientRoleType = { roleType: role.roleType };
              const validations = ParseValidationFlags(item.insuredObjectValidations[vesselId]);
              return (
                <CompanyWrapper key={`${role.organization.id}${index}`}>
                  <CompanyProvider
                    client={role.organization}
                    clientRoleType={clientRoleType}
                    activePeriod={role.activePeriod}
                    roleId={role.id}
                    roleEtag={role._etag}
                    covers={item.interestCodes}
                    fleetCovers={fleetCovers}
                    claims={claims}
                    isCoAssured={true}
                    fleetId={fleetId}
                    vesselId={vesselId}
                    mutateFleetDetails={mutateFleetDetails}
                    mutateVesselList={update}
                    organizationsListForChecking={coAssured}
                    organizationForCheckingIndex={index}
                    isRoleTypeEditable={true}
                    roleSourceSystem={getSourceSystem(role)}
                    isCompanyRole={true}
                    locationState={locationState}
                    setLocationState={setLocationState}
                    validations={validations}
                    entityType={'OrganizationRole'}
                  >
                    <Company />
                  </CompanyProvider>

                  {getSourceSystem(role) !== 'Ins2000' && (
                    <StyledTrash
                      data-test-id="remove-company-role"
                      onClick={() => {
                        handleRemoveCompanyRole(fleetId, vesselId, role.id, object._etag);
                      }}
                    />
                  )}
                  {loadingId && loadingId != role.id && !isError && <StyledDisabledTrash />}
                  {isError && loadingId === role.id && <ErrorMessage>Failed to remove company role.</ErrorMessage>}
                  {loadingId && loadingId === role.id && !isError && <StyledLoad />}
                </CompanyWrapper>
              );
            })}
          </Row>
        </Section>
      )}
      {isSearchCoAssuredOpen || coAssured.length === 0 ? (
        <SearchWrapper>
          <CompanySearch
            fleetId={fleetId}
            vesselId={vesselId}
            sectionTitle="Co-assured"
            onAdd={update}
            mutateFleetDetails={mutateFleetDetails}
            setIsSearchCoAssuredOpen={setIsSearchCoAssuredOpen}
            organizationsListForChecking={coAssured}
            covers={newRoleCovers}
            isCoAssured={true}
          />
        </SearchWrapper>
      ) : (
        <ButtonWrapper>
          <BoxButton
            data-test-id="add-coassured-company"
            aria-label="Add Co-assured"
            onClick={handleIsSearchCoAssuredOpen}
            padding="10px 20px"
            startIcon={<Add />}
            type="button"
          >
            CO-ASSURED
          </BoxButton>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

VesselData.propTypes = {
  fleetId: PropTypes.string,
  vesselId: PropTypes.string,
  fleetName: PropTypes.string,
  insuranceYear: PropTypes.number,
  onUpdate: PropTypes.func,
  mutateFleetDetails: PropTypes.func,
  newCompany: PropTypes.object,
  isCoAssured: PropTypes.bool,
  fleetCovers: PropTypes.array,
  claims: PropTypes.array,
  mutateWizardValidationTasks: PropTypes.func,
  locationState: PropTypes.object,
  setLocationState: PropTypes.func,
};

import React, { useMemo } from 'react';
import { Refresh, RefreshDotted, TimeMachine } from '@instech/icons';
import { postRevalidate, postResync } from 'services/postRevalidateResync';
import { TooltipButtons, TooltipButton } from './TooltipButtons';
import { enqueueFleetToFix } from "../../services/enqueueFleetToFix";
import { useAppContext } from "../appRouting/AppContext";

export const RevalidateResyncButtons = ({entityId, entityType, entityPartitionKey}) => {
  const {isAdministrator, isDataAdministrator} = useAppContext();
  const resyncRevalidatePayload = useMemo(
    () => ({entityId, entityType, entityPartitionKey}),
    [entityId, entityType, entityPartitionKey]
  );

  const actions = useMemo(() => [
    {
      handler: postRevalidate,
      payload: resyncRevalidatePayload,
      hidden: false,
      text: "Revalidate " + entityType.replace(/([A-Z])/g, ' $1'),
      buttonTestId:"revalidate-data",
      icon: Refresh
    },
    {
      handler: postResync,
      payload: resyncRevalidatePayload,
      hidden: !isAdministrator && !isDataAdministrator,
      text: "Resync " + entityType.replace(/([A-Z])/g, ' $1'),
      buttonTestId: "refresh-data",
      icon: RefreshDotted,
    },
    {
      handler: enqueueFleetToFix,
      payload: resyncRevalidatePayload,
      hidden: entityType !== 'Fleet',
      text: "Check and fix " + entityType.replace(/([A-Z])/g, ' $1'),
      buttonTestId: "check-fix-data",
      icon: TimeMachine,
    }
  ], [isAdministrator, isDataAdministrator, resyncRevalidatePayload]);

  return (
    <TooltipButtons actions={actions} />
  );
};

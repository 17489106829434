import React from 'react';
import styled from 'styled-components';
import { getFriendlyDate } from '../../utils/date';
import { useFleetOverviewContext } from './FleetOverviewContext';
import { RevalidateResyncButtons } from '../shared/RevalidateResyncButtons';
import { ContentBlock } from '../shared/ContentBlock';
import { useAppContext } from '../appRouting/AppContext';

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 0.5fr repeat(4, 1fr) 2fr;
  color: ${(props) => props.theme.marineBlue};
  border-bottom: 1px solid ${(props) => props.theme.marineBlue50};
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.5fr repeat(4, 1fr) 2fr;
  text-align: center;
  align-items: center;
  padding: 10px;
  line-height: 24px;

  &:nth-child(odd) {
    background: ${(props) => props.theme.flatWhite};
  }
`;

export const FleetCoversAndClaims = () => {
  const { fleetId, fleetCovers, fleetClaims, numberOfInsuredObjects } = useFleetOverviewContext() as {
    fleetId: string;
    fleetCovers: any[];
    fleetClaims: any[];
    numberOfInsuredObjects: number;
  };
  return (
    <>
      <ContentBlock title="Covers">
        <HeaderGrid>
          <span></span>
          <span>Objects in Fleet</span>
          <span>Interest Code</span>
          <span>Risk Number</span>
          <span>Cover Note</span>
          <span>Insurance Period</span>
        </HeaderGrid>
        {fleetCovers.map((item, index) => (
          <Grid key={index}>
            <RevalidateResyncButtons entityId={item.id} entityType={'cover'} entityPartitionKey={fleetId} />
            <span>{index === 0 ? numberOfInsuredObjects : null}</span>
            <span>{item.interestCode.name}</span>
            <span>{item.riskNumber ? item.riskNumber : ''}</span>
            <span>{item.coverNote ? item.coverNote : ''}</span>
            <span>
              {getFriendlyDate(item.insurancePeriod.from)} &nbsp; - &nbsp;
              {getFriendlyDate(item.insurancePeriod.to)}
            </span>
          </Grid>
        ))}
      </ContentBlock>
      {fleetClaims && fleetClaims.length > 0 && (
        <ContentBlock title="Claims">
          <HeaderGrid>
            <span>Object Name</span>
            <span>Claim Number</span>
            <span>Cover Note</span>
            <span>Insurance Year</span>
            <span></span>
          </HeaderGrid>
          {fleetClaims.map((claim, index) => (
            <Grid key={index}>
              <span>{claim.insuredObject.displayName}</span>
              <span>
                {claim.number}\{claim.sectionCode}
              </span>
              <span>{fleetCovers.map((el) => (el.id === claim.coverId ? el.coverNote : null))}</span>
              <span>{fleetCovers.map((el) => (el.id === claim.coverId ? el.insuranceYear : null))}</span>
              <span></span>
            </Grid>
          ))}
        </ContentBlock>
      )}
    </>
  );
};

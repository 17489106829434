export const constants = {
    SET_SECTIONS: 'setSections',
    SET_SECTIONS_FOR_QUOTED: 'setSectionsForQuoted',
    SET_IS_ONLY_WITH_ACTIVE_CLAIMS: 'setIsOnlyWithActiveClaims',
    SET_NEW_COMPANY: 'setNewCompany',
    SET_COMPANY_QUERY: 'setCompanyQuery',
    SET_PERSON_QUERY: 'setPersonQuery',
    SET_FLEET_QUERY: 'setFleetQuery',
    SET_FLEET_QUERY_FOR_QUOTED: 'setFleetQueryForQuoted',
    SET_SEARCH_BY_OBJECT: 'setSearchByObject',
    SET_SEARCH_BY_OBJECT_FOR_QUOTED: 'setSearchByObjectForQuoted',
    SET_SINGLE_COMPANY_ID: 'setSingleCompanyId',
    SET_OFFSET: 'setOffset',
    SET_CASE_HANDLER_EMAILS: 'setCaseHandlerEmails',
    SET_CASE_HANDLER_EMAILS_FOR_QUOTED: 'setCaseHandlerEmailsForQuoted',
    SET_IS_NEW_PERSON_FORM_OPEN: 'setIsNewPersonFormOpen',
    SET_SHOW_CONFIRM_REFRESH: 'setShowConfirmRefresh',
    SET_SHOW_SAVE_CONFLICT: 'setShowSaveConflict',
    SET_IS_SAVING: 'setIsSaving',
    SET_IS_REMOVING: 'setIsRemoving',
    SET_IS_ERROR: 'setIsError',
    SET_ERROR_MESSAGE: 'setErrorMessage',
    SET_IS_DIRTY: 'setIsDirty',
    SET_UPDATED_BY: 'setUpdatedBy',
    SET_SHOW_ROLE_EXISTS_MESSAGE: 'setShowRoleExistsMessage',
    SET_INITIAL_ROLE_TYPE: 'setInitialRoleType',
    SET_RISK_LEVEL: 'setRiskLevel',
    SET_ACTIVE_TAB_FOR_QUOTED: 'setActiveTabForQuoted',
    SET_ACTIVE_TAB: 'setActiveTab', 
    SET_CURRENT_PAGE: 'setCurrentPage',
    SET_IS_CONFIRM_DIALOG_VISIBLE: 'setIsConfirmDialogVisible'
    }

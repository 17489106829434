import * as Yup from 'yup';
import moment from 'moment-timezone';

const orgLeiErrorMessage = 'Organization Number or LEI Number is required';
const requiredErrorMessage = 'This field is required';
const stringLengthMessage = (length) => `Must be less than or equal to ${length} characters`;

const trimmedString = Yup.string().trim();

const validUrl = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

export const ValidationSchema = Yup.object({
  organizationNumber: trimmedString
    .nullable()
    .max(30, stringLengthMessage(30))
    .test({
      message: orgLeiErrorMessage,
      test: function () {
        return this.parent.organizationNumber || this.parent.lei;
      },
    }),

  lei: trimmedString
    .nullable()
    .max(20, stringLengthMessage(20))
    .test({
      message: orgLeiErrorMessage,
      test: function () {
        return this.parent.organizationNumber || this.parent.lei;
      },
    }),

  name: trimmedString.required(requiredErrorMessage).max(60, stringLengthMessage(60)),

  legalAddress: Yup.object().shape({
    country: Yup.object().shape({
      id: trimmedString.nullable().required(requiredErrorMessage),
    }),
    addressLine: trimmedString.nullable().required(requiredErrorMessage),
    state: Yup.object()
      .nullable()
      .shape({
        id: trimmedString.nullable(),
      }),
  }),

  legalForm: Yup.object()
      .shape({
          name: trimmedString
      }),

  vatNumber: trimmedString
    .nullable()
    .max(30, stringLengthMessage(30)),

  fiscalNumber: trimmedString
    .nullable()
    .max(20, stringLengthMessage(20)),

  clientRoleType: Yup.object().shape({
    roleType: Yup.object().shape({
      id: Yup.string().nullable().required(requiredErrorMessage),
    }),
  }),

  activePeriod: Yup.object().shape({
    from: Yup.date().nullable().label('From').default(null).typeError('Must be a `date` type'),
    to:
      'from' &&
      Yup.date()
        .min(Yup.ref('from'), "Must be after 'from' date.")
        .nullable()
        .label('To')
        .typeError('Must be a `date` type'),
  }),
});

function formatDate() {
  return new moment().toString();
}

export const ValidationSchemaAcceptDialog = Yup.object().shape({
  acceptanceDateFrom: Yup.date()
    .nullable()
    .required(requiredErrorMessage)
    .test('Is valid  ISO date', 'Invalid date', (value) => moment(value, moment.ISO_8601, true).isValid())
    .test('date', 'Date cannot be in the future', (value) => moment(value).isBefore(formatDate())),

  acceptanceDateTo: Yup.date()
    .min(Yup.ref('acceptanceDateFrom'), "Must be after 'from' date.")
    .nullable()
    .required(requiredErrorMessage),

  additionalComments: trimmedString.nullable().required(requiredErrorMessage),
});

export const ValidationSchemaForMultipleVesselsStepTwo = Yup.object().shape({
  objectIds: Yup.array().required('At least one checkbox is required'),
});

export const ValidationSchemaLink = Yup.object().shape({
  url: trimmedString.matches(validUrl, 'Enter a valid url').required(requiredErrorMessage),
});

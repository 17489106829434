import { constants } from '../../../utils/constants';
import { CreateCompany, UpdateCompany, CreateCompanyRole, UpdateCompanyRole } from '../../../services/postPutCompanyData';
import { formatErrorMessage } from '../../../utils/errorMessageFormatter';
import { v4 as uuidv4 } from 'uuid';

// Transform industries form values to backend industry model.
// - Use matching industry model from API
// - Include comment in wrapper for industries where comments are enabled (only "Other" for now)
const transformIndustries = (selectedIndustries, industryCustomComment, industriesData) => {
  return selectedIndustries.map(selected => {
    const industryData = industriesData.find(i => i.id == selected.value);
    return {
      industry: industryData,
      customComment: industryData?.isCustomCommentEnabled ? industryCustomComment : null,
    };
  });
};

export const handleSubmit = (
  values,
  setSubmitting,
  formDispatch,
  initialValues,
  industriesData,
  isNewCompany,
  isCoAssured,
  appDispatch,
  navigate,
  vesselId,
  fleetId,
  roleId,
  organization,
  mutateFleetDetails,
  mutateOrganizationRoles,
  roleEtag,
  isNewRoleType,
  setIsNewCompanyRoleTypeOpen,
  mutateVesselList,
  setIsSearchCoAssuredOpen,
  setIsSearchAssuredOpen,
  mutate,
  companyListMutate,
  setIsConfirmDialogVisible
) => {
  formDispatch({ type: constants.SET_INITIAL_ROLE_TYPE, value: values.clientRoleType });
  formDispatch({ type: constants.SET_IS_SAVING, value: true });
  setSubmitting(true);
  formDispatch({ type: constants.SET_IS_ERROR, value: false });
  formDispatch({ type: constants.SET_ERROR_MESSAGE, value: null });
  formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
  if (!values.legalAddress.state?.id) values.legalAddress.state = null;
  if (!values.legalAddress.country.id) values.legalAddress.country.id = '00000000-0000-0000-0000-000000000000';

  const returnUrl = `/fleets/${fleetId}`;

  const isClientRoleChanged =
    values.activePeriod &&
    (initialValues.activePeriod?.from !== values.activePeriod.from ||
      initialValues.activePeriod?.to !== values.activePeriod.to);

  const submitedValues = {
    id: values.id,
    organization: {
      ...values,
      industryCustomComment: undefined,
      industries: transformIndustries(values.industries, values.industryCustomComment, industriesData),
    },
  };

  if (isNewCompany) {
    const roleType = {
      id: '',
      roleName: '',
      isCoAssured: isCoAssured,
    };
    CreateCompany(submitedValues)
      .then((response) => {
        const newCompany = {
            roleType,
            isActive: true,
            organization: {
              id: response.id,
              displayName: response.name,
              displayAddress: response.legalAddress.addressLine,
              ...response,
          },
        };        
        setIsConfirmDialogVisible(true);
        formDispatch({ type: constants.SET_IS_SAVING, value: false });
        formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
        appDispatch({ type: constants.SET_NEW_COMPANY, value: newCompany });
        setTimeout(() => {
          navigate(returnUrl, {
            state: {
              vesselId: vesselId,
              clientId: response.id,
              isNewRoleType: true,
              isCoAssured: isCoAssured,
              isNewCompany: true,
            },
            replace: true,
          });
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        formDispatch({ type: constants.SET_ERROR_MESSAGE, value: formatErrorMessage(error) });
        formDispatch({ type: constants.SET_IS_ERROR, value: true });
        formDispatch({ type: constants.SET_IS_SAVING, value: false });
        formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
      });
  } else {
    UpdateCompany(submitedValues, values._etag)
      .then(async (response) => {
        const companyInterestCodes = values.covers && values.covers.length > 0 ? values.covers.map((c) => c.id) : [];
        const roleCommand = vesselId
          ? {
              id: uuidv4(),
              fleetId: fleetId,
              objectId: vesselId,
              organizationRoleId: roleId,
              roleType: values.clientRoleType.roleType,
              isActive: true,
              activePeriod: values.activePeriod,
              organizationId: values.id,
              twoLetterCountryCode: values.legalAddress.country.partitionKey,
              interestCodes: companyInterestCodes,
              ownerIsAssured: values.ownerIsAssured,
            }
          : null;

        const etag = response._etag;

        organization._etag = etag;

        mutateFleetDetails && (await mutateFleetDetails());

        if (mutateOrganizationRoles) {
          const orgRoles = await mutateOrganizationRoles();

          if (roleCommand) {
            const updatedRole = orgRoles.find((x) => x.id === roleCommand.organizationRoleId);

            if (updatedRole) {
              roleEtag = updatedRole._etag;
            }
          }
        }

        if (isNewRoleType) {
          await CreateCompanyRole(roleCommand)
            .then(async () => {
              formDispatch({ type: constants.SET_IS_SAVING, value: false });
              formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
              setIsNewCompanyRoleTypeOpen && setIsNewCompanyRoleTypeOpen(false);
              setTimeout(() => {
                navigate(returnUrl, {
                  state: {
                    vesselId: vesselId,
                    isNewRoleType: false,
                  },
                  replace: true,
                });
              }, 2000);
              mutateFleetDetails && (await mutateFleetDetails());
              mutateVesselList && (await mutateVesselList());
              mutateOrganizationRoles && (await mutateOrganizationRoles());
              isCoAssured && setIsSearchCoAssuredOpen && setIsSearchCoAssuredOpen(false);
              !isCoAssured && setIsSearchAssuredOpen && setIsSearchAssuredOpen(false);
            })
            .catch((error) => {
              console.log(error);
              formDispatch({ type: constants.SET_ERROR_MESSAGE, value: formatErrorMessage(error) });
              formDispatch({ type: constants.SET_IS_ERROR, value: true });
              formDispatch({ type: constants.SET_IS_SAVING, value: false });
              formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
            });
        } else if (roleCommand && isClientRoleChanged) {
          await UpdateCompanyRole(roleCommand, roleEtag)
            .then(async () => {
              formDispatch({ type: constants.SET_IS_SAVING, value: false });
              formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
              mutateVesselList && (await mutateVesselList());
              mutateFleetDetails && (await mutateFleetDetails());
              mutateOrganizationRoles && (await mutateOrganizationRoles());
              setIsNewCompanyRoleTypeOpen && setIsNewCompanyRoleTypeOpen(false);
              setTimeout(() => {
                navigate(returnUrl, {
                  state: {
                    vesselId: vesselId,
                  },
                });
              }, 2000);
            })
            .catch((error) => {
              console.log(error);
              formDispatch({ type: constants.SET_ERROR_MESSAGE, value: formatErrorMessage(error) });
              formDispatch({ type: constants.SET_IS_ERROR, value: true });
              formDispatch({ type: constants.SET_IS_SAVING, value: false });
              formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
            });
        } else {
          formDispatch({ type: constants.SET_IS_SAVING, value: false });
          formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });
        }

        setIsConfirmDialogVisible(true);
        await mutate();

        companyListMutate && (await companyListMutate());
        appDispatch && appDispatch({ type: constants.SET_NEW_COMPANY, value: null });
        setIsNewCompanyRoleTypeOpen && setIsNewCompanyRoleTypeOpen(false);
      })
      .catch((error) => {
        console.log(error);
        formDispatch({ type: constants.SET_ERROR_MESSAGE, value: formatErrorMessage(error) });
        formDispatch({ type: constants.SET_IS_ERROR, value: true });
        formDispatch({ type: constants.SET_IS_SAVING, value: false });
        formDispatch({ type: constants.SET_SHOW_CONFIRM_REFRESH, value: false });

        if (error.response?.status === 412) {
          setSubmitting(false);
          formDispatch({ type: constants.SET_SHOW_SAVE_CONFLICT, value: true });
        }
      });
  }
};

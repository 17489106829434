import { AcceptanceLog } from "../../services/useAcceptanceLogs";
import { getFriendlyDate } from "../../utils/date";
import React, { useState } from "react";
import styled from "styled-components";
import { DownArrow } from "@instech/icons";

const AdditionalInfoWrapper = styled.div`
  grid-column: span 4;
`;
const Item = styled.div`
  margin: 8px 0;
`;
const Title = styled.span`
  display: inline-block;
  width: 120px;
  margin: 0 32px;
  text-transform: uppercase;
  color: ${(props) => props.theme.marineBlue50};
`;
const ArrowDown = styled(DownArrow)`
  justify-self: right;
  color: ${(props) => props.theme.marineBlue};
  cursor: pointer;
`;
const ArrowUp = styled(DownArrow)`
  justify-self: right;
  transform: rotate(180deg);
  color: ${(props) => props.theme.marineBlue};
  cursor: pointer;
`;
export const AdditionalLogInfo = ({item}: {item: AcceptanceLog}) => {

    const [isAdditionalInfoOpen, setAdditionalInfoOpen] = useState(false);
    const handleOpenAdditionalInfo = () => setAdditionalInfoOpen(!isAdditionalInfoOpen);

    return (
        <>
            {isAdditionalInfoOpen ? (
                <ArrowUp data-test-id="open-info" onClick={handleOpenAdditionalInfo} />
            ) : (
                <ArrowDown data-test-id="close-info" onClick={handleOpenAdditionalInfo} />
            )}
            {isAdditionalInfoOpen && (
                <AdditionalInfoWrapper>
                    <Item>
                        <Title>Accepted From</Title>
                        <span>{getFriendlyDate(item.acceptancePeriod.from)}</span>
                    </Item>
                    <Item>
                        <Title>Accepted To</Title>
                        <span>{getFriendlyDate(item.acceptancePeriod.to)}</span>
                    </Item>
                </AdditionalInfoWrapper>
            )}
        </>
    )
}

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LogDataOrganization } from './LogDataOrganization';
import { LogDataOrganizationPerson } from './LogDataOrganizationPerson'

const Wrapper = styled.div`
  background: ${( props ) => props.theme.white};
`;

export const AcceptanceLogOrganization = ({ pageData }) => {
  return (
    <Wrapper>
      {pageData
        ? pageData.map((item, key) => {
            if (item.entityType === 'Organization') return <LogDataOrganization key={key} item={item} />;
            if (item.entityType === 'Person') return <LogDataOrganizationPerson key={item.id} item={item} />;
          })
        : null}
    </Wrapper>
  );
};

AcceptanceLogOrganization.propTypes = {
  pageData: PropTypes.array,
};

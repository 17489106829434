import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InputField, EmptyPlaceholderInputField } from '../../shared/formFields/InputField';
import { SelectField } from '../../shared/formFields/SelectField';
import { Label } from '../../shared/formFields/Label';
import { LabelWithIcon } from '../../shared/LabelWithIcon';
import { createExternalId } from '../../../utils/createExternalId';
import { useIndustries } from '../../../services/useIndustries';
import { setNamedCountryReference, setNamedStateReference } from '../helpers';
import { getStatesList } from '../../../services/getStatesList';
import { Dropdown } from '@instech/components';
import { TextAreaField } from "../../shared/formFields/TextAreaField";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 40px;
  column-gap: 16px;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 1205px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const AddressSection = styled.div`
  grid-column: 1/4;
  grid-row: span 2/4;
  align-self: stretch;
  textarea {
    height: 144px;
  }
`;

const DropdownSection = styled.div`
  grid-column: span 2;
  label {
    height: unset;
  }
  align-self: stretch;
`;

export const CompanyFormFields = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  countries,
}) => {
  const { data: industriesData } = useIndustries();
  // Map industries to {label: name, value: id} format that dropdown accepts
  const industries = useMemo(() => industriesData?.map(item => ({
    label: item.name,
    value: item.id
  })) ?? [], [industriesData]);

  const enableCommentForIndustries = useMemo(() =>
    !!values?.industries?.some(selectedIndustry =>
      industriesData?.find(industry => industry.id == selectedIndustry.value)?.isCustomCommentEnabled),
    [values?.industries, industriesData]);

  useEffect(() => {
    // Only clear if there was a change
    if (industriesData && !enableCommentForIndustries && values.industryCustomComment) {
      setFieldValue('industryCustomComment', '');
    }
  }, [enableCommentForIndustries])

  const handleCountryChange = (name, value) => {
    if (value !== values.legalAddress?.country?.id) {
      setFieldValue('legalAddress.state.id', '');
      setFieldValue('legalAddress.state.partitionKey', '');
      setFieldValue('legalAddress.state.displayName', '');
      setFieldValue('legalAddress.state.subdivisionCode', '');
      setFieldValue(name, value);
    }
  };

  const handleStateChange = (name, value) => {
    setFieldValue(name, value);
  };

  setNamedCountryReference(values.legalAddress?.country, countries);

  const shouldFetchStates = !!(values.legalAddress?.country);
  const states = getStatesList(values.legalAddress?.country?.partitionKey, shouldFetchStates);

  if (states && states.length > 0) {
    setNamedStateReference(values.legalAddress?.state, states);
  }

  return (
    <Grid>
      <div>
        <Label label='External ID' />
        <InputField
          name='id'
          value={createExternalId(values) || ''}
          type='text'
          disabled
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        />
      </div>
      <div>
        <LabelWithIcon
          labelText='Org. Number'
          infoText='Either organization number or LEI code is required'
          required={!values.lei}
          infoIcon
        />
        <InputField
          name='organizationNumber'
          value={values.organizationNumber || ''}
          type='text'
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        />
      </div>
      <div>
        <LabelWithIcon
          labelText='LEI Code'
          infoText='Either organization number or LEI code is required'
          required={!values.organizationNumber}
          infoIcon
        />
        <InputField
          labelName='LEI Code'
          name='lei'
          value={values.lei || ''}
          type='text'
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        />
      </div>
      <div>
        <Label label='Company name' required />
        <InputField
          name='name'
          value={values.name || ''}
          type='text'
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          validateOnChange={true}
        />
      </div>
      <div>
        <Label label='Country of Registration' required />
        <SelectField
          name='legalAddress.country.id'
          placeholder='Not selected'
          value={values.legalAddress?.country?.id || ''}
          handleChange={handleCountryChange}
          handleBlur={setFieldTouched}
          touched={touched}
          errors={errors}
          options={countries}
          displayNameSelector={(x) => x.name}
          valueNameSelector={(x) => x.id}
        />
      </div>
      <div>
        {(states && states.length > 0) || !values.legalAddress?.country?.id ? (
          <Label label='State' />
        ) : (
          <div></div>
        )}
        {states && states.length > 0 ? (
          <div>
            <SelectField
              name='legalAddress.state.id'
              value={values.legalAddress?.state?.id || ''}
              placeholder='Not selected'
              displayNameSelector={(x) => x.name}
              valueNameSelector={(x) => x.id}
              handleChange={handleStateChange}
              handleBlur={setFieldTouched}
              options={states}
            />
            <input
              type='hidden'
              name='legalAddress.state.partitionKey'
              value={values.legalAddress?.state?.partitionKey || ''}
            />
            <input
              type='hidden'
              name='legalAddress.state.displayName'
              value={values.legalAddress?.state?.displayName || ''}
            />
            <input
              type='hidden'
              name='legalAddress.state.subdivisionCode'
              value={values.legalAddress?.state?.subdivisionCode || ''}
            />
          </div>
        ) : !values.legalAddress.country.id ? (
          <EmptyPlaceholderInputField placeholder='Select country first...' />
        ) : (
          <div></div>
        )}
      </div>
      <AddressSection>
        <Label label='Address' required />
        <TextAreaField
          name='legalAddress.addressLine'
          value={values.legalAddress?.addressLine || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          rows={8}
        />
      </AddressSection>
      <div>
        <Label label='Legal form' required />
        <InputField
          name='legalForm.name'
          value={values.legalForm?.name || ''}
          type='text'
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          validateOnChange={true}
        />
      </div>
      <div>
        <Label label='VAT Number' />
        <InputField
          name='vatNumber'
          value={values.vatNumber || ''}
          type='text'
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div>
        <Label label='Fiscal Number' />
        <InputField
          name='fiscalNumber'
          value={values.fiscalNumber || ''}
          type='text'
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
        />
      </div>
      <DropdownSection>
        <Dropdown
          label='Industry (several can be selected)'
          multiDropdown
          name='industries'
          placeholder='Not selected'
          options={industries}
        />
      </DropdownSection>
      <div>
        <Label label='Other industry' />
        <InputField
          name='industryCustomComment'
          value={values.industryCustomComment}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          disabled={!enableCommentForIndustries}
        />
      </div>
    </Grid>
  );
};

CompanyFormFields.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  countries: PropTypes.array,
};
